











import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ElementRow',
  components: {
    PageBuilderElement: () => import("~/components/Palmers/PageBuilder/PageBuilderElement.vue")
  },
  props: {
    element: {
      type: Object,
      require: true
    }
  }
});
